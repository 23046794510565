import { type FC, useMemo } from 'react'

import { Stack, Typography } from '@mui/material'

import { getHelperTextIcon, getInputColor } from './helpers'
import { type InputState } from './types'

interface Props {
  text: string
  state: InputState
  helperTextIcon?: JSX.Element
  htmlFor?: string
}

export const CustomInputHelperText: FC<Props> = ({ text, state, helperTextIcon, htmlFor }) => {
  const color = useMemo(() => getInputColor(state), [state])

  return (
    <Stack direction='row' alignItems='center'>
      {getHelperTextIcon(state, color.normal, helperTextIcon)}
      <Typography
        component='label'
        htmlFor={htmlFor}
        variant='label1'
        sx={{
          position: 'relative',
          bottom: 1.5,
          color: color.normal,
          mx: 0.5,
          mt: 0.5
        }}
      >
        {text}
      </Typography>
    </Stack>
  )
}
