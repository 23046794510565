/* eslint-disable @typescript-eslint/indent */
import { useMemo, useState } from 'react'
import { useController } from 'react-hook-form'

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  type OutlinedInputProps,
  Stack,
  type SxProps,
  Typography
} from '@mui/material'
import { SuccessIcon, VisibilityIcon, VisibilityOffIcon, WarningIcon } from '@core/components/icons'
import { colors } from '@core/helpers'
import { generateUUID } from '@core/helpers/uuid'

import { getInputColor } from '../helpers'
import { type InputState } from '../types'
import { getHelperText } from './helpers'

type Props = Partial<OutlinedInputProps> & {
  control: any
  borderColor?: string
  isPassword?: boolean
  width?: string | number
  successCondition?: boolean
  disabled?: boolean
  disableBorder?: boolean
  helperIcon?: JSX.Element
  helperText?: string
  label?: string
  labelOutside?: boolean
  name: string
  sxInput?: SxProps
  stateInput?: InputState
  successMessage?: string
  warningCondition?: boolean
  warningMessage?: string
  sxContainer?: SxProps
}
export const RHFCustomInput = ({
  control,
  successCondition,
  disabled,
  disableBorder = false,
  helperIcon,
  helperText,
  label,
  width,
  sxContainer,
  sxInput,
  labelOutside = true,
  name,
  fullWidth,
  isPassword,
  stateInput,
  borderColor,
  successMessage,
  type,
  size,
  warningCondition = false,
  warningMessage = '¡Atención!',
  ...props
}: Props) => {
  const labelId = `select-${name ?? generateUUID()}-label`
  const [inputType, setInputType] = useState(type || (isPassword ? 'password' : 'text'))

  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  const inputState = useMemo((): InputState => {
    if (disabled) return 'disabled'
    if (stateInput) return stateInput
    if (error) return 'error'
    if (successCondition) return 'success'
    if (warningCondition) return 'warning'

    return 'normal'
  }, [warningCondition, successCondition, error, stateInput, disabled])

  const color = useMemo(() => getInputColor(inputState), [inputState])

  const helperObj = useMemo(
    () =>
      getHelperText({
        color,
        inputState,
        errorMessage: error?.message,
        helperIcon,
        helperText,
        successMessage,
        warningMessage
      }),
    [color, inputState, error, helperIcon, helperText, successMessage, warningMessage]
  )

  return (
    <FormControl sx={{ ...sxContainer }} component='div' fullWidth={fullWidth} variant='outlined'>
      {labelOutside ? (
        <Typography
          component='label'
          htmlFor={labelId}
          sx={{ color: colors.neutral_gray_09 }}
          variant='body3'
          mb='0.4rem'
        >
          {label}
        </Typography>
      ) : (
        <InputLabel
          component='label'
          htmlFor={labelId}
          size={size === 'small' ? 'small' : 'normal'}
          sx={{ color: 'var(--color-text-body-b2)' }}
          color={inputState !== 'disabled' && inputState !== 'normal' ? inputState : undefined}
        >
          {label}
        </InputLabel>
      )}

      <OutlinedInput
        {...props}
        {...field}
        id={labelId}
        size={size}
        label={!labelOutside ? label?.slice(0, -2) : undefined}
        disabled={disabled}
        error={error && !disabled}
        fullWidth={fullWidth}
        sx={{
          width,
          '& .MuiOutlinedInput-notchedOutline': {
            border: disableBorder ? 'none' : undefined,
            borderColor: borderColor ?? color.normal
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.normal
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.hover
            }
          },
          ...sxInput
        }}
        endAdornment={
          isPassword ? (
            <InputAdornment sx={{ left: '0.8rem', position: 'relative' }} position='end'>
              <IconButton
                onClick={() =>
                  inputType === 'password' ? setInputType('text') : setInputType('password')
                }
              >
                {inputType === 'password' ? (
                  <VisibilityIcon size={24} color={colors.primary} />
                ) : (
                  <VisibilityOffIcon size={24} color={colors.primary} />
                )}
              </IconButton>
            </InputAdornment>
          ) : inputState === 'error' ? (
            <WarningIcon size={16} color={color.normal} />
          ) : inputState === 'success' ? (
            <SuccessIcon size={16} color={color.normal} />
          ) : inputState === 'warning' ? (
            <WarningIcon size={16} color={color.normal} />
          ) : undefined
        }
        type={inputType}
      />
      {helperObj.text && (
        <Stack direction='row' alignItems='center'>
          {helperObj.icon}
          <FormHelperText
            component='label'
            htmlFor={labelId}
            sx={{
              position: 'relative',
              bottom: 1.5,
              color: color.normal,
              mx: 0.5,
              mt: 0.5
            }}
          >
            {helperObj.text}
          </FormHelperText>
        </Stack>
      )}
    </FormControl>
  )
}
