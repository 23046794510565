import React, { type FC, useMemo } from 'react'
import { type Control, useController } from 'react-hook-form'

import {
  FormControl,
  MenuItem,
  Select,
  type SelectProps,
  Stack,
  type SxProps,
  Typography
} from '@mui/material'
import { ChevronDownIcon } from '@core/components/icons'
import { colors } from '@core/helpers'
import { type SelectOption } from '@core/types/components'

import { getInputColor } from '../helpers'
import { type InputState } from '../types'
import { getHelperText } from './helpers'

type Props = Partial<SelectProps> & {
  control: Control<any>
  successCondition?: boolean
  width?: string | number
  disabled?: boolean
  helperIcon?: JSX.Element
  helperText?: string
  label?: string
  name: string
  sxLabel?: SxProps
  sxSelect?: SxProps
  sxContainer?: SxProps
  options: SelectOption[]
  disableBorder?: boolean
  stateInput?: InputState
  successMessage?: string
  warningCondition?: boolean
  warningMessage?: string
}

export const RHFCustomSelect: FC<Props> = ({
  control,
  successCondition,
  disabled,
  width,
  helperIcon,
  helperText,
  label,
  name,
  options,
  sxLabel,
  sxSelect,
  sxContainer,
  fullWidth,
  stateInput,
  disableBorder = false,
  successMessage = 'Campo completado',
  warningCondition = false,
  warningMessage = '¡Atención!',
  ...props
}: Props) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  const labelId = `select-${name}-label`

  const inputState = useMemo((): InputState => {
    if (disabled) return 'disabled'
    if (stateInput) return stateInput
    if (error) return 'error'
    if (successCondition) return 'success'
    if (warningCondition) return 'warning'

    return 'normal'
  }, [warningCondition, error, successCondition, stateInput, disabled])

  const color = useMemo(() => getInputColor(inputState), [inputState])

  const IconComponent: React.ElementType = ({ className }) => (
    <ChevronDownIcon className={className} color='var(--color-primary)' />
  )

  const helperObj = useMemo(
    () =>
      getHelperText({
        color,
        inputState,
        errorMessage: error?.message,
        helperIcon,
        helperText,
        successMessage,
        warningMessage
      }),
    [color, inputState, error, helperIcon, helperText, successMessage, warningMessage]
  )

  return (
    <FormControl sx={sxContainer} fullWidth={fullWidth}>
      <Typography
        component='label'
        htmlFor={labelId}
        sx={{ color: colors.neutral_gray_09, ...sxLabel }}
        variant='body3'
        mb={0.5}
      >
        {label}
      </Typography>
      <Select
        {...field}
        {...props}
        id={labelId}
        value={field?.value ?? ''}
        labelId={labelId}
        fullWidth={fullWidth}
        error={error !== undefined}
        disabled={disabled}
        IconComponent={IconComponent}
        MenuProps={{
          sx: {
            '& .MuiPaper-root': {
              maxHeight: '360px',
              boxShadow: 'var(--shadow-neutral-5)'
            }
          }
        }}
        sx={{
          width,
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '2px',
              borderColor: color.hover
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: disableBorder ? 'none' : undefined,
            borderColor: color.normal
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.hover
            }
          },
          ...sxSelect
        }}
        onChange={(e, child) => {
          field.onChange(e)

          if (props?.onChange) {
            props.onChange(e, child)
          }
        }}
      >
        {options?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
      {helperObj.text && (
        <Stack direction='row' alignItems='center'>
          {helperObj.icon}
          <Typography
            component='label'
            htmlFor={labelId}
            variant='label1'
            sx={{
              color: color.normal,
              m: 0.5
            }}
          >
            {helperObj.text}
          </Typography>
        </Stack>
      )}
    </FormControl>
  )
}
