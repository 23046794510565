import { type ReactNode } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { useLayout } from '@core/contexts/layout'
import { colors } from '@core/helpers'

import s from './Cards.module.scss'

interface Props {
  icon: ReactNode
  type: 'descuento' | 'precio'
  text: string
  valor: string
  backgroundColor?: string
}

export const CardServicios = ({ icon, type, text, valor, backgroundColor = 'white' }: Props) => {
  const { isMobileSmall } = useLayout()

  return (
    <Grid
      item
      phone_xs={6}
      phone_md={4}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      className={s['card-servicios']}
      gap='0.8rem'
      sx={{
        backgroundColor,
        borderRadius: '1.2rem',
        p: '2rem 1.6rem',
        minWidth: '12rem',
        maxWidth: isMobileSmall ? '14rem' : '16rem'
      }}
      flex={1}
    >
      {icon}
      <Typography variant='label1_semi_bold' color={colors.text_body_b2}>
        {text}
      </Typography>
      {valor && (
        <Box display='flex' alignItems='center' gap='0.6rem'>
          {type === 'descuento' ? (
            <>
              <Typography variant='h4_bold' color={colors.text_title}>
                {valor}
              </Typography>
              <Typography variant='label1_semi_bold'>Descuento</Typography>
            </>
          ) : (
            <>
              <Typography variant='h4_bold' color={colors.text_title}>
                {valor}
              </Typography>
              <Typography variant='label1_semi_bold'>Soles</Typography>
            </>
          )}
        </Box>
      )}
    </Grid>
  )
}
