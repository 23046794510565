import { type FC } from 'react'
import { useController } from 'react-hook-form'

import { Checkbox, Stack, type SxProps, Typography, type TypographyProps } from '@mui/material'
import { CheckboxUncheckedIcon, WarningIcon2 } from '@core/components/icons'

type Props = TypographyProps & {
  control: any
  label?: string | React.ReactElement
  name: string
  spacing?: number | string
  sxContainer?: SxProps
  sxLabel?: SxProps
  disableLabelClickeable?: boolean
}

export const RHFCustomCheckbox: FC<Props> = ({
  control,
  label,
  name,
  spacing = 1.5,
  sxContainer,
  sxLabel,
  disableLabelClickeable = false,
  variant = 'link_small',
  ...props
}) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  const labelId = `checkbox-${name}-label`

  return (
    <Stack sx={{ ...sxContainer }}>
      <Stack direction='row' gap={spacing} alignItems='center'>
        <Checkbox
          name={field.name}
          id={labelId}
          value={field.value}
          checked={field.value}
          onChange={(e, checked) => field.onChange(checked)}
          icon={error ? <CheckboxUncheckedIcon color='var(--color-error)' /> : undefined}
        />
        {label && (
          <Stack
            component='label'
            sx={{ cursor: disableLabelClickeable ? undefined : 'pointer' }}
            htmlFor={disableLabelClickeable ? undefined : labelId}
          >
            <Typography
              // color={error ? 'error' : undefined}
              variant={variant}
              {...props}
              sx={{ ...sxLabel }}
            >
              {label}
            </Typography>
          </Stack>
        )}
      </Stack>
      {error?.message && (
        <Stack direction='row' alignItems='center'>
          <WarningIcon2 size={13} color='var(--color-error)' />

          <Typography
            component='label'
            htmlFor={labelId}
            variant='label1'
            sx={{
              color: 'var(--color-error)',
              m: 0.5
            }}
          >
            {error.message}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}
