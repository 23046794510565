import { forwardRef } from 'react'

import cn from 'clsx'

import Dialog, { type DialogProps } from '@mui/material/Dialog'
import Slide, { type SlideProps } from '@mui/material/Slide'
import { isEmptyValue } from '@core/lib/utils'

import { CustomDialogHeading, type CustomDialogHeadingProps } from './CustomDialogHeading'

import s from './CustomDialog.module.scss'

type Props = DialogProps & {
  id: string
  open: boolean
  children: React.ReactNode
  fullScreen?: boolean
  headingProps?: Partial<CustomDialogHeadingProps>
  title?: string
  onClose?: () => void
}

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function CustomDialog({
  id,
  open,
  children,
  fullScreen,
  headingProps,
  title,
  onClose,
  ...props
}: Props) {
  const labelledby = `${id}-dialog-title`

  return (
    <Dialog
      open={open}
      {...props}
      scroll='paper'
      fullScreen={fullScreen}
      aria-labelledby={labelledby}
      TransitionComponent={fullScreen ? Transition : undefined}
      onClose={onClose}
      classes={{
        paper: cn(s.paper, { [s['is-fullscreen']]: fullScreen })
      }}
      componentsProps={{
        backdrop: {
          className: s.backdrop
        }
      }}
    >
      {!isEmptyValue(title) && (
        <CustomDialogHeading id={labelledby} onClose={onClose} {...headingProps}>
          <h2>{title}</h2>
        </CustomDialogHeading>
      )}
      {children}
    </Dialog>
  )
}
