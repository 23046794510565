/* eslint-disable @typescript-eslint/indent */
import { type FC } from 'react'
import Link from 'next/link'

import cn from 'clsx'

interface Props {
  variant?: 'outlined' | 'contained' | 'outlined_white'
  size?: 'normal' | 'large'
  className?: string
  title: string | JSX.Element | JSX.Element[]
  href: string
  target?: string
  endIcon?: JSX.Element
  startIcon?: JSX.Element
}

export const LinkButton: FC<Props> = ({
  variant = 'contained',
  className = '',
  title,
  target,
  href,
  size = 'normal',
  startIcon,
  endIcon
}) => {
  const buttonType =
    variant === 'contained'
      ? 'link-btn-secondary'
      : variant === 'outlined_white'
      ? 'link-btn-outlined-white'
      : 'link-btn-outlined'

  const buttonSize = size === 'large' ? 'link-btn-large' : ''

  return (
    <Link href={href} target={target} className={cn(buttonType, buttonSize, className)}>
      {startIcon} {title}
      {endIcon}
    </Link>
  )
}
