import { type ReactNode, useState } from 'react'

import cn from 'clsx'

import { Box, Stack, Typography } from '@mui/material'

import s from './Alert.module.scss'

interface Props {
  startIcon?: ReactNode
  endIcon?: ReactNode
  title?: string
  text: string
  severity: 'warning' | 'error' | 'success' | 'info'
}

export const AlertBasic = ({ startIcon, endIcon, text, severity, title }: Props) => {
  const [isSHowingAlert, setIsSHowingAlert] = useState(true)

  return isSHowingAlert ? (
    <Box
      display='flex'
      justifyContent='space-between'
      p='1.2rem'
      // my='1.6rem'
      className={cn(s.root, s[`is-${severity}`])}
    >
      <Box display='flex' gap='0.8rem' alignItems='center'>
        {startIcon}

        <Stack>
          <Typography variant='body3_semi_bold'>{title}</Typography>
          <Typography variant='body3_semi_bold'>{text}</Typography>
        </Stack>
      </Box>
      <Box component='button' onClick={() => setIsSHowingAlert(false)} sx={{ cursor: 'pointer' }}>
        {endIcon}
      </Box>
    </Box>
  ) : (
    <></>
  )
}
