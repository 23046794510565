import { type FC } from 'react'

import parser from 'html-react-parser'

import { Paper, type PaperProps, type SxProps, Typography } from '@mui/material'

type Props = PaperProps & {
  icon: (size: number) => JSX.Element
  label: string
  sxContainer?: SxProps
}

export const CardIconSmall: FC<Props> = ({ icon, label, sxContainer, ...rest }) => {
  return (
    <Paper
      {...rest}
      elevation={0}
      sx={{
        gap: 2,
        width: '11.1rem',
        height: '10.8rem',
        borderRadius: '0.8rem',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        ...sxContainer
      }}
    >
      {icon(24)}
      <Typography color='secondary' textAlign='center' variant='label1_semi_bold'>
        {parser(label)}
      </Typography>
    </Paper>
  )
}
