/* eslint-disable @typescript-eslint/indent */
import { type Dispatch, type FC, type SetStateAction } from 'react'

import { Button, Typography } from '@mui/material'
import { SuccessIcon2, WarningIcon2 } from '@core/components/icons/UI'
import { CustomDialogControlled } from '@core/components/ui/CustomDialog'
import { colors } from '@core/helpers'
import { useMedia } from '@core/hooks/useMedia'

export interface AlertProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onContinue?: VoidFunction
  onClose?: VoidFunction
  icon?: (size: number) => JSX.Element
  title: string | React.ReactElement | JSX.Element
  description?: string | React.ReactElement | JSX.Element
  customFooter?: JSX.Element
  footerButtonText?: string
  footerButtonHref?: string
  type?: 'success' | 'error' | 'warning' | 'info'
  showCloseIcon?: boolean
}

const AlertDialog: FC<AlertProps> = ({
  open,
  setOpen,
  onContinue,
  customFooter,
  onClose,
  description,
  title,
  icon,
  type = 'default',
  footerButtonText = 'Continuar',
  footerButtonHref,
  showCloseIcon
}) => {
  const isMoreThanMobileMedium = useMedia('(min-width: 648px)', false)

  return (
    <CustomDialogControlled
      open={open}
      showCloseIcon={showCloseIcon}
      onClose={onClose}
      setOpen={setOpen}
      sxDialogContent={{ maxWidth: '58.8rem' }}
      icon={size =>
        icon ? (
          icon(size)
        ) : type === 'success' ? (
          <SuccessIcon2 color={colors.primary_light} size={size} />
        ) : (
          <WarningIcon2
            color={
              type === 'info'
                ? colors.primary
                : type === 'error'
                ? colors.error
                : type === 'warning'
                ? colors.warning
                : colors.info
            }
            size={size}
          />
        )
      }
      header={
        typeof title === 'string' ? (
          <Typography
            textAlign='center'
            sx={{ color: 'var(--color-primary-darkest)' }}
            variant='h3_bold'
            component='h3'
          >
            {title}
          </Typography>
        ) : (
          title
        )
      }
      content={() =>
        typeof description === 'string' ? (
          <Typography
            mb={isMoreThanMobileMedium ? '1.6rem' : '0.8rem'}
            textAlign='center'
            variant={isMoreThanMobileMedium ? 'body2' : 'body3'}
            component='p'
            color={colors.text_body_b1}
          >
            {description}
          </Typography>
        ) : (
          description
        )
      }
      footer={() =>
        customFooter !== undefined ? (
          customFooter
        ) : (
          <Button
            href={footerButtonHref}
            sx={{ width: 200, alignSelf: 'center' }}
            onClick={onContinue}
          >
            {footerButtonText}
          </Button>
        )
      }
    />
  )
}
export default AlertDialog
