import { useRef, useState } from 'react'

import { type DateTime } from 'luxon'

import { IconButton, type PopperPlacementType, type SxProps } from '@mui/material'
import { type StaticDatePickerProps } from '@mui/x-date-pickers-pro'
import { CalendarIcon, CloseIcon } from '@core/components/icons'
import { CustomInput } from '@core/components/ui'
import { useLayout } from '@core/contexts/layout'
import { colors, ISOToLuxonDateTime } from '@core/helpers'

import DateModal from './DateModal/DateModal'
import DatePopper from './DatePopper/DatePopper'

type Props = Omit<StaticDatePickerProps<DateTime>, 'onChange' | 'value'> & {
  onChange: (values: string | null) => void
  value: string | null
  width?: string | number
  fullWidth?: boolean
  labelId?: string
  helperText?: string
  label?: string
  error?: boolean
  placeholder?: string
  borderColor?: string
  disableBorder?: boolean
  disabled?: boolean
  sxInput?: SxProps
  placement?: PopperPlacementType
}

export default function CustomDatePicker({
  onChange,
  value,
  width,
  placement,
  fullWidth,
  label,
  borderColor,
  disableBorder,
  placeholder,
  disabled,
  sxInput,
  labelId,
  helperText,
  error,
  ...pickerProps
}: Props) {
  const ref = useRef(null)

  const [openDatePicker, setOpenDatePicker] = useState(false)
  const { isMobileSmall } = useLayout()
  const [isInYearView, setIsInYearView] = useState(false)

  const handleOpen = () => {
    setOpenDatePicker(true)
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e?.target.blur()
    setOpenDatePicker(true)
  }
  const handleClose = () => {
    setOpenDatePicker(false)
  }
  const onClear = () => {
    onChangeDate(null)
  }

  const onChangeDate = (date: string | null) => {
    if (!isInYearView) setOpenDatePicker(false)
    onChange(date)
  }

  return (
    <>
      <CustomInput
        sx={{ ...sxInput }}
        name='date-range-picker'
        refContainer={ref}
        id={labelId}
        helperText={helperText}
        error={error}
        label={label}
        focused={openDatePicker}
        fullWidth={fullWidth}
        disabled={disabled}
        disableBorder={disableBorder}
        borderColor={borderColor}
        width={width ?? '100%'}
        value={value ? ISOToLuxonDateTime(value).toFormat('dd/MM/yyyy') : ''}
        inputProps={{ readOnly: true }}
        placeholder={placeholder}
        onFocus={onFocus}
        autoComplete='off'
        endAdornment={
          !disabled ? (
            <IconButton
              sx={{ position: 'relative', left: '1rem' }}
              onClick={!value ? handleOpen : onClear}
            >
              {value ? (
                <CloseIcon color={colors.primary} />
              ) : (
                <CalendarIcon color={colors.primary} />
              )}
            </IconButton>
          ) : undefined
        }
      />

      {isMobileSmall ? (
        <DateModal
          {...pickerProps}
          open={openDatePicker}
          initialValue={value}
          onChange={onChangeDate}
          setIsInYearView={setIsInYearView}
          onClose={handleClose}
        />
      ) : (
        <DatePopper
          {...pickerProps}
          placement={placement}
          setIsInYearView={setIsInYearView}
          open={openDatePicker}
          anchorEl={ref.current}
          initialValue={value}
          onChange={onChangeDate}
          onClose={handleClose}
        />
      )}
    </>
  )
}
