import { type Dispatch, type SetStateAction } from 'react'

import { type Breakpoint, Dialog, DialogContent, Stack, type SxProps } from '@mui/material'
import { CloseIcon } from '@core/components/icons'
import { useLayout } from '@core/contexts/layout'

type Props = {
  header?: JSX.Element | React.ReactNode | React.ReactElement
  footer?: (close: VoidFunction) => JSX.Element | React.ReactNode | React.ReactElement
  content: (close: VoidFunction) => JSX.Element | React.ReactNode | React.ReactElement
  icon?: (size: number) => JSX.Element
  maxWidth?: false | Breakpoint | undefined
  sxDialog?: SxProps
  sxDialogContent?: SxProps
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
  showCloseIcon?: boolean
  fullScreen?: boolean
}

export default function CustomDialogControlled({
  icon,
  header,
  content,
  footer,
  maxWidth = 'tablet_lg',
  onClose,
  open,
  fullScreen,
  setOpen,
  showCloseIcon = true,
  sxDialog,
  sxDialogContent,
  ...props
}: Props) {
  const closeModal = () => setOpen(false)

  const { isMobile, isMobileLarge } = useLayout()

  const handleOnClose = () => {
    if (typeof onClose === 'function') onClose()
    closeModal()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      {...props}
      sx={{ ...sxDialog }}
      open={open}
      onClose={handleOnClose}
      maxWidth={maxWidth}
    >
      <DialogContent
        sx={{
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: isMobileLarge ? '4rem 4rem' : isMobile ? '2.4rem 2.4rem' : '4.8rem',
          ...sxDialogContent
        }}
      >
        {showCloseIcon && (
          <Stack
            onClick={handleOnClose}
            component='div'
            position='absolute'
            sx={{ cursor: 'pointer', top: '1.8rem', right: '1.8rem' }}
          >
            <CloseIcon size={18} />
          </Stack>
        )}
        {icon && (
          <Stack
            width='100%'
            component='section'
            mb={isMobile ? '1.6rem' : '3.2rem'}
            alignItems='center'
          >
            {icon(isMobileLarge ? 70 : isMobile ? 48 : 80)}
          </Stack>
        )}
        {header && (
          <Stack width='100%' alignItems='center' component='section' mb='1.6rem'>
            {header}
          </Stack>
        )}
        <Stack
          width='100%'
          alignItems='center'
          component='section'
          mb={isMobile ? '2.4rem' : '4rem'}
        >
          {content(closeModal)}
        </Stack>
        {footer && <Stack width='100%'>{footer(closeModal)}</Stack>}
      </DialogContent>
    </Dialog>
  )
}
