import { type FC, useState } from 'react'
import Link from 'next/link'

import { Card, type CardProps, Stack, type SxProps, Typography } from '@mui/material'
import { ChevronRightIcon } from '@core/components/icons'
import { colors } from '@core/helpers'

type Props = CardProps & {
  icon: (color: string, size: number) => JSX.Element
  description: string
  title: string
  href: string
  sxCard?: SxProps
  active: boolean
}

export const CardCuenta: FC<Props> = ({
  icon,
  title,
  href,
  description,
  sxCard,
  active,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <Card
      component={Link}
      href={href}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
      sx={{
        width: '34.4rem',
        display: 'flex',
        padding: '1.6rem',
        alignItems: 'center',
        borderColor: active ? colors.primary_lighter : colors.white,
        justifyContent: 'flex-start',
        gap: '1.6rem',
        '&:hover': {
          bgcolor: colors.white,
          borderColor: colors.primary_lighter
        },
        ...sxCard
      }}
    >
      <Stack
        component='div'
        alignSelf='center'
        sx={{
          bgcolor: colors.primary_lightest,
          borderRadius: '4rem',
          width: '4rem',
          height: '4rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {icon(colors.primary, 24)}
      </Stack>

      <Stack flex={1} component='div' direction='column' spacing={0.5}>
        <Typography
          sx={{
            color: active ?? isHovered ? colors.primary_dark : colors.text_title
          }}
          variant='body1_semi_bold'
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: colors.text_body_b2
          }}
          variant='label1_semi_bold'
        >
          {description}
        </Typography>
      </Stack>

      {active && (
        <Stack>
          <ChevronRightIcon size='2.4rem' color={colors.primary} />
        </Stack>
      )}
    </Card>
  )
}
