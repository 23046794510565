import { type Dispatch, type SetStateAction, useEffect, useRef } from 'react'

import { type DateTime } from 'luxon'

import { DialogContent, Stack } from '@mui/material'
import { type DateView, type StaticDatePickerProps } from '@mui/x-date-pickers-pro'
import { CustomDialog } from '@core/components/ui'
import { useLayout } from '@core/contexts/layout'

import StaticDatePicker from '../StaticDatePicker'

type Props = Omit<StaticDatePickerProps<DateTime>, 'onChange'> & {
  open: boolean
  initialValue: string | null
  onChange: (values: string | null) => void
  onClose: () => void
  setIsInYearView: Dispatch<SetStateAction<boolean>>
}

export default function DateModal({
  open,
  initialValue,
  onChange,
  onClose,
  setIsInYearView,
  ...pickerProps
}: Props) {
  const { isMobileLarge, isTablet } = useLayout()

  const isMoreThanMobileMedium = isMobileLarge || isTablet

  const timeout = useRef<NodeJS.Timeout>()
  const onViewChange = (view: DateView) => {
    if (view === 'year') setIsInYearView(true)
    else {
      timeout.current = setTimeout(() => setIsInYearView(false), 100)
    }
  }

  useEffect(() => {
    return () => clearTimeout(timeout.current)
  }, [])

  return (
    <CustomDialog
      id='appoiment-date'
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 0
        }
      }}
      title='Seleccionar fecha'
      fullScreen
      headingProps={{ isReversed: true }}
      onClose={onClose}
    >
      <DialogContent sx={{ pt: 1.5, pb: 2 }}>
        <Stack alignItems='center' justifyContent='center' width='100%' height='70vh'>
          <StaticDatePicker
            {...pickerProps}
            onViewChange={onViewChange}
            displayType={isMoreThanMobileMedium ? 'desktop' : 'mobile'}
            initialValue={initialValue}
            onChange={onChange}
          />
        </Stack>
      </DialogContent>
    </CustomDialog>
  )
}
