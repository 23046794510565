import { type DateTime } from 'luxon'

import {
  LocalizationProvider,
  StaticDatePicker as MuiStaticDatePicker,
  type StaticDatePickerProps
} from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import { ISOToLuxonDateTime } from '@core/helpers'

import s from './StaticDatePicker.module.scss'

type Props = Omit<StaticDatePickerProps<DateTime>, 'onChange'> & {
  displayType: 'desktop' | 'mobile'
  initialValue: string | null
  onChange: (values: string | null) => void
}

export default function StaticDatePicker({ displayType, initialValue, onChange, ...props }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='es'>
      <MuiStaticDatePicker
        {...props}
        className={s.picker}
        value={ISOToLuxonDateTime(initialValue)}
        displayStaticWrapperAs={displayType}
        // dayOfWeekFormatter={day => dayOfWeekFormatter(day, locale)}
        slots={{
          actionBar: () => null
        }}
        slotProps={{
          // textField: { variant: 'outlined' },
          toolbar: {
            hidden: true
          },
          actionBar: {
            hidden: true
          }
        }}
        onChange={dateTime => dateTime && onChange(dateTime.toISO())}
      />
    </LocalizationProvider>
  )
}
