import { type CSSProperties, type FC } from 'react'
import NextLink, { type LinkProps } from 'next/link'

import cn from 'clsx'

import { Box, Typography, type TypographyProps } from '@mui/material'
import { ArrowLeftIcon, ArrowRight } from '@core/components/icons'

import s from './LinkText.module.scss'

type Props = Omit<LinkProps, 'href'> & {
  text: string
  size?: 'small' | 'medium' | 'large' | 'xLarge'
  href?: string
  disableIcon?: boolean
  iconUbi?: 'left' | 'right'
  customIcon?: (color: string, size: number) => JSX.Element
  classNameLink?: string
  sxTypography?: CSSProperties
  component?: 'box' | 'link'
  target?: string
  color?: string
  iconColor?: string
  styleContainer?: CSSProperties
  typographyProps?: TypographyProps
  onClick?: VoidFunction
}

export const LinkText: FC<Props> = ({
  text,
  size = 'medium',
  href = '#',
  color = 'var(--color-primary)',
  customIcon,
  disableIcon = false,
  iconUbi = 'right',
  classNameLink,
  sxTypography,
  styleContainer,
  iconColor,
  target,
  onClick,
  typographyProps,
  component = 'link',
  ...rest
}) => {
  const variantClass = {
    xLarge: s['link-xLarge'],
    large: s['link-large'],
    medium: s['link-medium'],
    small: s['link-small']
  }
  const sizeType = {
    xLarge: 24,
    large: 20,
    medium: 18,
    small: 15
  }

  const variantType = variantClass[size] || variantClass.medium
  const currentSizeType = sizeType[size] || sizeType.medium

  const showIcon = (): JSX.Element | null => {
    return customIcon?.(color, currentSizeType) ?? null
  }

  const isBox = component === 'box'

  return (
    <Box
      {...rest}
      component={isBox ? Box : NextLink}
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
      href={isBox ? '#' : href}
      target={isBox ? undefined : target}
      style={{ color, ...styleContainer }}
      className={cn(s['link-wrapper'], classNameLink)}
    >
      {iconUbi === 'left' &&
        (disableIcon ? null : customIcon ? (
          showIcon()
        ) : (
          <ArrowLeftIcon color={iconColor ?? color} size={currentSizeType} />
        ))}

      <Typography {...typographyProps} sx={{ color, ...sxTypography }} className={variantType}>
        {text}
      </Typography>

      {iconUbi === 'right' &&
        (disableIcon ? null : customIcon ? (
          showIcon()
        ) : (
          <ArrowRight color={iconColor ?? color} size={currentSizeType} />
        ))}
    </Box>
  )
}
