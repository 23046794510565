import { type FC, useState } from 'react'

import { type DateTime } from 'luxon'

import { Stack, type SxProps, TextField, Typography } from '@mui/material'
import { DatePicker, type DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'

import { CalendarIconButton } from './CalendarIconButton'
import { CustomInputHelperText } from './CustomInputHelperText'

type Props = DatePickerProps<DateTime> & {
  value: DateTime | null
  onChange: (date: DateTime) => void
  label?: string
  placeholder?: string
  showIcon?: boolean
  disabled?: boolean
  error?: boolean
  width?: string | number
  labelOutside?: boolean
  borderColor?: string
  name: string
  sxTextField?: SxProps
  fullWidth?: boolean
  size?: 'small' | 'medium'
  helperText?: string
}

export const CustomDatePicker: FC<Props> = ({
  onChange,
  placeholder,
  showIcon = false,
  label,
  value,
  error,
  disabled,
  fullWidth,
  width,
  sxTextField,
  name,
  helperText,
  size,
  labelOutside = true,
  borderColor = 'var(--color-neutral-gray-05)',
  ...props
}) => {
  const state = error ? 'error' : disabled ? 'disabled' : 'normal'

  const labelId = `select-${name}-label`
  const [openPicker, setOpenPicker] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Stack width={fullWidth ? '100%' : width} component='div'>
        {labelOutside && (
          <Typography
            htmlFor={labelId}
            component='label'
            sx={{ color: 'var(--color-text-body-b2)' }}
            variant='body3'
            mb={0.5}
          >
            {label}
          </Typography>
        )}
        <DatePicker
          {...props}
          open={openPicker}
          // label={!labelOutside ? label : undefined}
          value={value}
          slots={{
            textField: props => {
              return (
                <TextField
                  {...props}
                  sx={{
                    width,
                    cursor: 'pointer',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor
                    },
                    ...sxTextField
                  }}
                  id={labelId}
                  variant='outlined'
                  // error={error !== undefined}
                  autoComplete='off'
                  placeholder={placeholder ?? 'DD/MM/YYYY'}
                  size={size}
                  fullWidth={fullWidth}
                  onClick={() => setOpenPicker(true)}
                  InputProps={{
                    ...props?.InputProps,
                    // disableUnderline: true,
                    endAdornment: showIcon ? (
                      <CalendarIconButton onClick={() => setOpenPicker(true)} />
                    ) : undefined
                  }}
                />
              )
            }
          }}
          onChange={onChange}
          onClose={() => setOpenPicker(false)}
          onAccept={() => setOpenPicker(false)}
        />
        {helperText && <CustomInputHelperText state={state} text={helperText} htmlFor={labelId} />}
      </Stack>
    </LocalizationProvider>
  )
}
