import { type FC, useRef, useState } from 'react'

import { Card, type CardProps, Stack, type SxProps, Typography } from '@mui/material'
import { LinkText } from '@core/components/common/Button'

type Props = CardProps & {
  icon: (color: string, size: number) => JSX.Element
  category: string
  title: string
  href: string
  target?: string
  sxCard?: SxProps
}

export const CardEspecialidad: FC<Props> = ({
  icon,
  title,
  href,
  target,
  category,
  sxCard,
  ...rest
}) => {
  const color = useRef('var(--color-primary)')
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <Card
      component='li'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
      sx={{
        width: '392px',
        display: 'flex',
        padding: '24px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '16px',
        ...sxCard
      }}
    >
      <Stack component='div' alignSelf='flex-start'>
        {icon(color.current, 36)}
      </Stack>
      <Stack component='div' direction='column' spacing={0.5}>
        <Typography
          sx={{
            color: isHovered ? 'var(--color-primary-darkest)' : 'var(--color-text-body-b2)'
          }}
          variant='label1_semi_bold'
        >
          {category}
        </Typography>
        <Typography
          sx={{
            color: 'var(--color-primary-darkest)'
          }}
          variant='body1_semi_bold'
        >
          {title}
        </Typography>
        <LinkText size='small' href={href} target={target} text='Ver detalle' />
      </Stack>
    </Card>
  )
}
