import { Stack, Typography } from '@mui/material'
import { LinkText } from '@core/components/common/Button'
import { ClinicalIcon, DownloadIcon } from '@core/components/icons'
import { useLayout } from '@core/contexts/layout'
import { colors } from '@core/helpers'
import { type TramiteProps } from '@core/types/home'

interface Props {
  tramiteUsuario: TramiteProps
  onOpenDialogDesgargas: () => void
}

export const CardTramites = ({ tramiteUsuario, onOpenDialogDesgargas }: Props) => {
  const { isMobile } = useLayout()
  const { tramite } = tramiteUsuario

  return (
    <Stack
      borderRadius='1.2rem'
      p={isMobile ? '1.6rem 1rem' : '1.6rem'}
      pb='2rem'
      width='100%'
      height='14.4rem'
      justifyContent='space-between'
      sx={{ backgroundColor: 'white' }}
    >
      <ClinicalIcon />
      <Typography
        variant='body3_bold'
        color={colors.text_title_p2}
        mb='2.4rem'
        mt='1.2rem'
        sx={{ lineHeight: '1.9rem' }}
      >
        {tramite}
      </Typography>
      <LinkText
        component='box'
        text='Descargar'
        onClick={onOpenDialogDesgargas}
        iconUbi='right'
        customIcon={(color, size) => <DownloadIcon color={color} size={size} />}
      />
    </Stack>
  )
}
