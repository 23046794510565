/* eslint-disable @typescript-eslint/indent */
import { type ChangeEvent, forwardRef, type RefObject, useMemo, useState } from 'react'

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  type OutlinedInputProps,
  type SxProps,
  Typography
} from '@mui/material'
import { SuccessIcon, VisibilityIcon, VisibilityOffIcon, WarningIcon } from '@core/components/icons'
import { colors } from '@core/helpers'

import { CustomInputHelperText } from './CustomInputHelperText'
import { getInputColor } from './helpers'
import { type InputState } from './types'

export type CustomInputProps = Omit<OutlinedInputProps, 'onChange'> & {
  borderColor?: string
  disableBorder?: boolean
  disabled?: boolean
  error?: boolean
  helperIcon?: JSX.Element
  helperText?: string
  label?: string
  labelOutside?: boolean
  focused?: boolean
  name: string
  onChangeNative?: (e: ChangeEvent) => void
  onChange?: (value: string) => void
  refContainer?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined
  stateInput?: InputState
  sxContainer?: SxProps
  sxInput?: SxProps
  value?: string
  isPassword?: boolean
  width?: string | number
  sxLabel?: SxProps
}
const CustomInput = forwardRef(
  (
    {
      borderColor,
      disableBorder,
      disabled,
      endAdornment,
      error,
      fullWidth,
      helperIcon,
      isPassword,
      helperText,
      label,
      labelOutside = true,
      name,
      onChange,
      refContainer,
      focused,
      size,
      stateInput,
      onChangeNative,
      sxContainer,
      sxInput,
      type = 'text',
      value,
      width,
      sxLabel,
      ...props
    }: CustomInputProps,
    ref
  ) => {
    const labelId = `select-${name}-label`
    const [inputType, setInputType] = useState(type ?? (isPassword ? 'password' : 'text'))

    const state: InputState = disabled
      ? 'disabled'
      : error
      ? 'error'
      : stateInput !== undefined
      ? stateInput
      : 'normal'

    const color = useMemo(() => getInputColor(state), [state])

    return (
      <FormControl
        ref={refContainer}
        focused={focused}
        sx={{ ...sxContainer }}
        fullWidth={fullWidth}
      >
        {labelOutside ? (
          <Typography
            component='label'
            htmlFor={labelId}
            sx={{ color: colors.neutral_gray_09, ...sxLabel }}
            variant='body3'
            mb='0.4rem'
          >
            {label}
          </Typography>
        ) : label ? (
          <InputLabel
            component='label'
            htmlFor={labelId}
            size={size === 'small' ? 'small' : 'normal'}
            error={error && !disabled}
            sx={{ color: 'var(--color-text-body-b2)', ...sxLabel }}
            color={state !== 'disabled' && state !== 'normal' ? state : undefined}
          >
            {label}
          </InputLabel>
        ) : null}
        <OutlinedInput
          {...props}
          ref={ref}
          name={name}
          id={labelId}
          label={!labelOutside ? label?.slice(0, -1) : undefined}
          disabled={disabled}
          fullWidth={fullWidth}
          value={value}
          size={size}
          onChange={
            onChange
              ? ({ target }) => onChange(target.value)
              : onChangeNative
              ? e => onChangeNative(e)
              : undefined
          }
          error={error ?? (state === 'error' && !disabled)}
          sx={{
            width,
            '& .MuiOutlinedInput-notchedOutline': {
              border: disableBorder ? 'none' : undefined,
              borderColor: borderColor ?? color.normal
            },
            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px',
                borderColor: color.hover
              }
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: color.hover
              }
            },
            ...sxInput
          }}
          endAdornment={
            endAdornment ??
            (isPassword ? (
              <InputAdornment sx={{ left: '0.8rem', position: 'relative' }} position='end'>
                <IconButton
                  onClick={() =>
                    inputType === 'password' ? setInputType('text') : setInputType('password')
                  }
                >
                  {inputType === 'password' ? (
                    <VisibilityIcon size={24} color={colors.primary} />
                  ) : (
                    <VisibilityOffIcon size={24} color={colors.primary} />
                  )}
                </IconButton>
              </InputAdornment>
            ) : error === true || state === 'error' ? (
              <WarningIcon size={16} color={color.normal} />
            ) : state === 'success' ? (
              <SuccessIcon size={16} color={color.normal} />
            ) : state === 'warning' ? (
              <WarningIcon size={16} color={color.normal} />
            ) : undefined)
          }
          type={inputType}
        />
        {helperText && (
          <CustomInputHelperText
            state={state}
            text={helperText}
            htmlFor={labelId}
            helperTextIcon={helperIcon}
          />
        )}
      </FormControl>
    )
  }
)

CustomInput.displayName = 'CustomInput'
export { CustomInput }
