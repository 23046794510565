import { type PopperProps } from '@mui/material'

export const popperModifiers: PopperProps['modifiers'] = [
  {
    name: 'offset',
    options: {
      offset: [0, 10]
    }
  },
  {
    name: 'flip',
    enabled: false
  },
  {
    name: 'preventOverflow',
    enabled: false
  }
]
