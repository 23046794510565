import { IconButton, InputAdornment } from '@mui/material'
import { CalendarIcon } from '@core/components/icons'

type Props = {
  onClick: () => void
}

export const CalendarIconButton = ({ onClick }: Props) => {
  return (
    <InputAdornment position='end'>
      <IconButton
        aria-label='Abrir calendario'
        onClick={onClick}
        edge='end'
        disableRipple
        sx={{
          position: 'inherit',
          marginRight: '-4px',
          '&:hover': {
            backgroundColor: 'transparent'
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%'
          }
        }}
      >
        <CalendarIcon />
      </IconButton>
    </InputAdornment>
  )
}
