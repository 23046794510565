import { type FC } from 'react'
import { useController } from 'react-hook-form'

import { type DateTime } from 'luxon'

import { type PopperPlacementType, type SxProps } from '@mui/material'
import { type DatePickerProps } from '@mui/x-date-pickers-pro'
import { generateUUID } from '@core/helpers/uuid'

import CustomDatePicker from '../DatePicker/CustomDatePicker'

type Props = DatePickerProps<DateTime> & {
  borderColor?: string
  control: any
  fullWidth?: boolean
  helperText?: string
  label?: string
  name: string
  placeholder?: string
  sxTextField?: SxProps
  placement?: PopperPlacementType
  width?: string | number
}

export const RHFCustomDatePicker: FC<Props> = ({
  borderColor,
  control,
  fullWidth,
  helperText,
  name,
  label,
  placeholder,
  sxTextField,
  placement,
  disabled,
  width,
  ...props
}) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  const labelId = `select-${name ?? generateUUID()}-label`

  return (
    <CustomDatePicker
      {...props}
      label={label}
      labelId={labelId}
      disabled={disabled}
      value={field.value}
      placement={placement}
      error={Boolean(error?.message)}
      helperText={helperText ?? error?.message}
      sxInput={sxTextField}
      width={width}
      borderColor={borderColor}
      placeholder={placeholder ?? 'DD/MM/YYYY'}
      fullWidth={fullWidth}
      onChange={value => field.onChange(value)}
    />
  )
}
