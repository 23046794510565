import { Box, Stack, Typography } from '@mui/material'
import { CheckIcon } from '@core/components/icons'
import { useLayout } from '@core/contexts/layout'
import { colors } from '@core/helpers'

interface Props {
  label: string
  value: string | number
}

export const DetailItem = ({ label, value }: Props) => {
  const { isMobile } = useLayout()

  return (
    <Box display='flex' gap='1.2rem' width='100%'>
      <CheckIcon size={isMobile ? 20 : 24} color={colors.success} />
      <Stack>
        <Typography variant='label1_semi_bold' color={colors.text_body_b2}>
          {label}
        </Typography>
        <Typography variant='body3_semi_bold' color={colors.text_title_p2}>
          {value}
        </Typography>
      </Stack>
    </Box>
  )
}
