import { Box, Stack, Typography } from '@mui/material'
// import { useLayout } from '@core/contexts/layout'
import { type ListadoAfi } from '@services/programs/types'

import { colors } from '../../../@core/helpers/colors'

interface Props {
  affiliate: ListadoAfi
}

export const Afiliados = ({ affiliate }: Props) => {
  // const { isMobile } = useLayout()

  const { affiliateFullName, documentType, documentNumber } = affiliate

  return (
    <Box display='flex' justifyContent='space-between'>
      <Stack justifyContent='center'>
        <Typography variant='body3_semi_bold' color={colors.text_title}>
          {affiliateFullName}
        </Typography>
        <Typography variant='label1' color={colors.text_body_b2}>
          {documentType}: {documentNumber}
        </Typography>
      </Stack>
      {/* <Button
        variant='text'
        endIcon={<PencilIcon size={16} color={colors.primary} />}
        sx={{ display: 'flex', mr: isMobile ? '-0.5rem' : '-1rem', px: '1rem', py: 0 }}
      >
        <Typography variant='label1_semi_bold' color={colors.text_title_p1}>
          Editar {!isMobile && 'información'}
        </Typography>
      </Button> */}
    </Box>
  )
}
