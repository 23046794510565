import { Box, Grid, Stack, Typography } from '@mui/material'
import { useLayout } from '@core/contexts/layout'
import { colors } from '@core/helpers'
import { type DetallesPago } from '@core/types/portal'

import { DetailItem } from './DetailItem'

interface Props {
  detallesPago: DetallesPago
}

export const DetallesDePago = ({ detallesPago }: Props) => {
  const { programa, fecha, monto, cuotasPagadas, hora } = detallesPago

  const { isMobile } = useLayout()

  const details = [
    { label: 'Programa', value: programa },
    { label: 'Cuotas pagadas', value: cuotasPagadas },
    { label: 'Fecha', value: fecha },
    { label: 'Monto pagado', value: monto },
    { label: 'Hora', value: hora }
  ]

  return (
    <Stack gap={isMobile ? '1.6rem' : '2.4rem'} width='100%'>
      <Box display='flex'>
        <Typography variant='h4_bold' color={colors.text_title_p2}>
          Detalle <span style={{ color: colors.text_title_p1 }}>de tu pago</span>
        </Typography>
      </Box>
      <Grid container columnSpacing='2.4rem' rowSpacing='1.6rem'>
        {details.map((detail, index) => (
          <Grid item min={12} phone_xl={6} tablet_md={4} key={index}>
            <DetailItem label={detail.label} value={detail.value} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}
