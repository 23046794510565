import Image from 'next/image'

import { Box, Stack, Typography } from '@mui/material'
import { colors } from '@core/helpers'
import { MINI_BANNER_PATH } from '@core/lib/utils'

interface Props {
  contratante: string
  // fechaDePago: string
}

export const InfoPagos = ({ contratante }: Props) => {
  return (
    <Box sx={{ backgroundColor: 'white' }} borderRadius='1.6rem 1.6rem 0 0'>
      <Image
        quality={100}
        loading='eager'
        width={344}
        src={MINI_BANNER_PATH}
        height={183}
        alt='Banner que explica los beneficios de los acompañantes'
        style={{ width: '100%', height: 'auto' }}
      />

      <Box sx={{ py: '3.2rem', px: '3.2rem' }}>
        <Typography component='h3' variant='h4_bold' color={colors.text_title_p2}>
          Datos de pago
        </Typography>
        <Stack gap='0.4rem' mb='1.2rem' mt='2.4rem'>
          <Typography component='label' variant='label1_semi_bold' color={colors.text_body_b2}>
            Contratante
          </Typography>
          <Typography component='p' variant='body3_semi_bold' color={colors.text_title_p2}>
            {contratante}
          </Typography>
        </Stack>
      </Box>
    </Box>
  )
}
