import { SuccessIcon2, WarningIcon2 } from '@core/components/icons'

import { type InputState } from '../types'

export interface HelperTextReturn {
  text: string | undefined
  icon: JSX.Element | undefined
}

export interface IColor {
  normal: string
  hover: string
}

interface GetHelperTextParams {
  inputState: InputState
  color: IColor
  helperText?: string
  helperIcon?: JSX.Element
  errorMessage?: string
  successMessage?: string
  warningMessage?: string
}

export const getHelperText = ({
  color,
  inputState,
  errorMessage,
  successMessage,
  warningMessage,
  helperIcon,
  helperText
}: GetHelperTextParams): HelperTextReturn => {
  if (errorMessage !== undefined || inputState === 'error') {
    return { icon: <WarningIcon2 size={13} color={color.normal} />, text: errorMessage }
  }

  if (successMessage !== undefined && inputState === 'success') {
    return { icon: <SuccessIcon2 size={13} color={color.normal} />, text: successMessage }
  }

  if (warningMessage !== undefined && inputState === 'warning') {
    return { icon: <WarningIcon2 size={13} color={color.normal} />, text: warningMessage }
  }

  return { icon: helperIcon, text: helperText }
}
