import { type Dispatch, type SetStateAction, useEffect, useRef } from 'react'

import { type DateTime } from 'luxon'

import { Fade, Paper, Popper, type PopperPlacementType, type PopperProps } from '@mui/material'
import { type DateView, type StaticDatePickerProps } from '@mui/x-date-pickers-pro'
import { useClickAway } from '@hooks/useClickAway'

import { popperModifiers } from '../consts'
import StaticDatePicker from '../StaticDatePicker/StaticDatePicker'

import s from './DatePopper.module.scss'

type Props = Omit<StaticDatePickerProps<DateTime>, 'onChange'> & {
  open: boolean
  anchorEl: PopperProps['anchorEl']
  initialValue: string | null
  onChange: (values: string | null) => void
  onClose: () => void
  setIsInYearView: Dispatch<SetStateAction<boolean>>
  placement?: PopperPlacementType
}

export default function DatePopper({
  open,
  anchorEl,
  initialValue,
  onChange,
  onClose,
  setIsInYearView,
  placement = 'bottom',
  ...pickerProps
}: Props) {
  const ref = useRef(null)
  const timeout = useRef<NodeJS.Timeout>()

  useClickAway(ref, () => {
    if (open) {
      onClose()
    }
  })

  const onViewChange = (view: DateView) => {
    if (view === 'year') setIsInYearView(true)
    else {
      timeout.current = setTimeout(() => setIsInYearView(false), 100)
    }
  }

  useEffect(() => {
    return () => clearTimeout(timeout.current)
  }, [])

  return (
    <Popper
      id='travel-date-popper'
      ref={ref}
      anchorEl={anchorEl}
      modifiers={popperModifiers}
      open={open}
      disablePortal
      sx={{ zIndex: 999 }}
      placement={placement}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={100}>
          <Paper className={s.paper}>
            <StaticDatePicker
              {...pickerProps}
              displayType='desktop'
              onViewChange={onViewChange}
              initialValue={initialValue}
              onChange={onChange}
            />
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}
