/* eslint-disable @typescript-eslint/indent */
import { type FC, useEffect, useState } from 'react'

import { Stack, type SxProps, Typography } from '@mui/material'

interface Props {
  label: string
  disableCursorPointer?: boolean
  icon?: (color: string, size: number) => JSX.Element
  selectedValue?: string
  filled?: boolean
  disabled?: boolean
  className?: string
  onClick?: (value: string) => void
  value?: string
  variant?: 'rounded' | 'squared'
  size?: 'large' | 'small' | 'medium'
  sxContainer?: SxProps
}

export const CustomTag: FC<Props> = ({
  label,
  icon,
  sxContainer,
  disabled,
  onClick,
  filled = false,
  selectedValue,
  value,
  className,
  disableCursorPointer,
  variant = 'rounded',
  size = 'medium'
}) => {
  const isSquared = variant === 'squared'
  const isActive = filled ? true : value && selectedValue ? value === selectedValue : false

  const [color, setColor] = useState(
    isSquared ? 'var(--color-black)' : 'var(--color-primary-light)'
  )
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  useEffect(() => {
    if (isActive || isHovered) {
      setColor(isSquared ? 'var(--color-primary)' : 'var(--color-white)')
    } else setColor(isSquared ? 'var(--color-primary)' : 'var(--color-primary-light)')
  }, [isActive, isHovered, isSquared])

  const getBackgroundColor = () => {
    if (isSquared) {
      if (isHovered || isActive) return 'var(--color-primary-lightest)'

      return 'transparent'
    }
    if (isActive) return 'var(--color-primary)'
    if (isHovered) return 'var(--color-primary)'

    return 'trasnsparent'
  }

  const getPadding = () => {
    if (isSquared) {
      if (size === 'large') return '11px 14px'
      if (size === 'medium') return '9px 12px'

      return '7px 10px'
    }

    if (size === 'large') return '11px 18px'
    if (size === 'medium') return '9px 16px'

    return '7px 14px'
  }

  const getTypographyColor = () => {
    if (isSquared) {
      if (isActive) return 'var(--color-primary)'
      if (isHovered) return 'var(--color-text-body-b1)'

      return 'var(--color-text-body-b1)'
    }

    if (isActive) return 'var(--color-white)'
    if (isHovered) return 'var(--color-white)'

    return 'var(--color-primary-light)'
  }
  const getBorderColor = () => {
    if (isSquared) {
      if (isActive) return 'var(--color-primary)'
      if (isHovered) return 'var(--color-primary-lightest)'

      return 'var(--color-primary-lighter)'
    }

    if (isActive) return 'var(--color-primary)'
    if (isHovered) return 'var(--color-primary)'

    return 'var(--color-primary-lighter)'
  }

  return (
    <Stack
      onClick={onClick && value && !disabled ? () => onClick(value) : undefined}
      className={className}
      component='div'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      direction='row'
      sx={{
        transition: 'all ease 0.3s',
        cursor: disableCursorPointer === true || disabled ? undefined : 'pointer',
        borderRadius: isSquared ? '8px' : '500px',
        border: `solid 1px ${getBorderColor()}`,
        padding: getPadding(),
        gap: '4px',
        display: 'flex',
        width: '100rem',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: getBackgroundColor(),
        ...sxContainer
      }}
    >
      {icon?.(color, 12)}
      <Typography sx={{ color: getTypographyColor() }} variant='button_small' component='p'>
        {label}
      </Typography>
    </Stack>
  )
}
