import { type FC, useEffect, useState } from 'react'

import parser from 'html-react-parser'

import { Card, type SxProps, Typography } from '@mui/material'

interface Props {
  icon: (color: string) => JSX.Element
  title: string
  selectedValue?: string | null
  onClick?: (value?: string | null) => void
  value?: string | null
  direction?: 'column' | 'row'
  sxCard?: SxProps
  iconUbi?: 'left' | 'right'
}

export const CardTarjeta: FC<Props> = ({
  icon,
  title,
  onClick,
  selectedValue,
  value,
  sxCard,
  direction = 'column',
  iconUbi = 'right'
}) => {
  const [color, setColor] = useState('var(--color-neutral-gray-07)')
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  const isActive = value === selectedValue

  const getBorderColor = () => {
    if (isActive || isHovered) return 'var(--color-primary-light)'

    return 'var(--color-text-body-b3)'
  }

  useEffect(() => {
    if (isActive || isHovered) setColor('var(--color-primary')
    else setColor('var(--color-neutral-gray-07)')
  }, [isActive, isHovered])

  const isRow = direction === 'row'

  return (
    <Card
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClick?.(value ?? '')}
      sx={{
        cursor: 'pointer',
        width: isRow ? '312px' : '150px',
        display: 'flex',
        padding: '12px 16px',
        alignItems: 'center',
        justifyContent: isRow ? 'space-between' : 'center',
        flexDirection: isRow ? (iconUbi === 'right' ? 'row-reverse' : 'row') : 'column',
        gap: 1,
        borderColor: getBorderColor(),
        '&:hover': {
          borderColor: isActive ? 'var(--color-primary-light)' : undefined
        },
        ...sxCard
      }}
    >
      {icon(color)}
      <Typography
        sx={{
          color: isHovered || isActive ? 'var(--color-primary)' : 'var(--color-text-body-b2)'
        }}
        textAlign='center'
        variant='body3'
      >
        {parser(title)}
      </Typography>
    </Card>
  )
}
