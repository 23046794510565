import cn from 'clsx'

import { IconButton } from '@mui/material'
import { CloseIcon } from '@core/components/icons'

import s from './CustomDialog.module.scss'

export type CustomDialogHeadingProps = {
  id: string
  isReversed?: boolean
  closeColor?: 'primary' | 'dark' | 'black'
  children: React.ReactNode
  onClose?: () => void
}

export function CustomDialogHeading({
  id,
  isReversed,
  closeColor = 'primary',
  children,
  onClose
}: CustomDialogHeadingProps) {
  return (
    <div className={cn(s.heading, { [s['is-reversed']]: isReversed })} id={id}>
      {children}
      {onClose ? (
        <IconButton
          className={cn(s.close, s[`is-${closeColor}`], { [s['is-reversed']]: isReversed })}
          aria-label='close'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  )
}
