/* eslint-disable @typescript-eslint/indent */
/* eslint-disable prettier/prettier */
import { type FC, useState } from 'react'

import { Card, Radio, Stack, type SxProps, Typography } from '@mui/material'
import { RadioButtonUnChecked } from '@core/components/icons'

interface Props {
  selectedValue: string | null
  onClick: (value: string) => void
  value: string
  title: string
  subtitle?: string
  direction?: 'row' | 'column'
  sxCard?: SxProps
}

export const CardRadio: FC<Props> = ({
  value,
  selectedValue,
  onClick,
  subtitle,
  title,
  sxCard,
  direction = 'column'
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const checked = value === selectedValue

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  const isRow = direction === 'row'

  const typographyColor = isHovered
    ? 'var(--color-black)'
    : checked
    ? 'var(--color-primary-darkest)'
    : 'var(--color-text-body-b2)'

  return (
    <Card
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClick(value)}
      sx={{
        cursor: 'pointer',
        borderWidth: 1,
        py: isRow ? 1 : 2,
        pl: 1,
        pr: 2,
        width: 236,
        borderColor: checked ? 'var(--color-primary)' : 'var(--color-text-body-b3)',
        display: 'flex',
        flexDirection: isRow ? 'row' : 'column',
        alignItems: isRow ? 'center' : 'flex-start',
        justifyContent: isRow ? 'space-between' : 'center',
        '&:hover': {
          borderColor: checked ? 'var(--color-primary-light)' : undefined
        },
        ...sxCard
      }}
    >
      <Stack direction='row' alignItems='center'>
        <Radio
          icon={
            isHovered ? <RadioButtonUnChecked color='var(--color-primary)' size={24} /> : undefined
          }
          value={value}
          checked={checked}
          size='medium'
        />
        <Typography
          sx={{
            color: typographyColor
          }}
          fontWeight={600}
          variant={checked ? 'body3_semi_bold' : 'body3'}
        >
          {title}
        </Typography>
      </Stack>
      {subtitle && (
        <Typography
          sx={{ color: 'var(--color-text-body-b2)', ml: 5.2 }}
          textAlign='center'
          variant='label1_semi_bold'
        >
          {subtitle}
        </Typography>
      )}
    </Card>
  )
}
