/* eslint-disable @typescript-eslint/indent */
import { useMemo } from 'react'

import {
  FormControl,
  MenuItem,
  Select,
  type SelectProps,
  type SxProps,
  Typography
} from '@mui/material'
import { ChevronDownIcon } from '@core/components/icons'
import { type SelectOption } from '@core/types/components'

import { CustomInputHelperText } from './CustomInputHelperText'
import { getInputColor } from './helpers'
import { type InputState } from './types'

type Props<T> = Omit<SelectProps, 'onChange'> & {
  borderColor?: string
  disabled?: boolean
  error?: boolean
  helperIcon?: JSX.Element
  helperText?: string
  label?: string
  disableBorder?: boolean
  name: string
  width?: string | number
  sxLabel?: SxProps
  sxSelect?: SxProps
  sxContainer?: SxProps
  onChange?: (value: T) => void
  stateInput?: InputState
  value: T
  options: SelectOption[]
}

export const CustomSelect = <T extends {}>({
  disabled,
  error,
  width,
  borderColor,
  helperIcon,
  helperText,
  label,
  name,
  fullWidth,
  sxContainer,
  disableBorder = false,
  sxLabel,
  sxSelect,
  onChange,
  stateInput,
  options,
  value,
  ...props
}: Props<T>) => {
  const labelId = `select-${name}-label`

  const state: InputState = disabled
    ? 'disabled'
    : error
    ? 'error'
    : stateInput !== undefined
    ? stateInput
    : 'normal'

  const color = useMemo(() => getInputColor(state), [state])

  return (
    <FormControl sx={sxContainer} fullWidth={fullWidth}>
      <Typography sx={{ color: 'var(--color-text-body-b2)', ...sxLabel }} variant='body3' mb={0.5}>
        {label}
      </Typography>
      <Select
        {...props}
        value={value ?? ''}
        labelId={labelId}
        label={undefined}
        fullWidth={fullWidth}
        error={error !== undefined}
        disabled={disabled}
        IconComponent={({ className }) => (
          <ChevronDownIcon size={24} className={className} color={color.hover} />
        )}
        sx={{
          width,
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '2px',
              borderColor: color.hover
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: disableBorder ? 'none' : undefined,
            borderColor: borderColor ?? color.normal
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.hover
            }
          },
          ...sxSelect
        }}
        onChange={e => onChange?.(e.target.value as T)}
      >
        {options?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <CustomInputHelperText
          state={state}
          text={helperText}
          htmlFor={labelId}
          helperTextIcon={helperIcon}
        />
      )}
    </FormControl>
  )
}
