/* eslint-disable @typescript-eslint/indent */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'

import { Card, Radio, Stack, type SxProps, Typography } from '@mui/material'
import { RadioButtonUnChecked } from '@core/components/icons'

interface Props<T> {
  fullWidth?: boolean
  selectedValue: T | null
  spacing?: number | string
  subtitle?: string
  sxCard?: SxProps
  title: string
  value: T
  width?: number | string

  icon?: (color: string, size: number) => JSX.Element
  onClick: (value: T) => void
}

export const CardRadioIcon = <T extends {}>({
  fullWidth,
  spacing = '1rem',
  subtitle,
  sxCard,
  title,
  value,
  width,
  selectedValue,

  onClick,
  icon
}: Props<T>) => {
  const [isHovered, setIsHovered] = useState(false)

  const [color, setColor] = useState('var(--color-neutral-gray-07)')
  const checked = value === selectedValue

  useEffect(() => {
    if (checked || isHovered) setColor('var(--color-primary')
    else setColor('var(--color-neutral-gray-07)')
  }, [checked, isHovered])

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  const typographyColor = isHovered
    ? 'var(--color-black)'
    : checked
    ? 'var(--color-primary-darkest)'
    : 'var(--color-text-body-b2)'

  return (
    <Card
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClick(value)}
      sx={{
        cursor: 'pointer',
        borderWidth: 1,
        py: 1.5,
        px: 1,
        gap: spacing,
        width: fullWidth ? '100%' : width !== undefined ? width : '328px',
        borderColor: checked ? 'var(--color-primary)' : 'var(--color-text-body-b3)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:hover': {
          borderColor: checked ? 'var(--color-primary-light)' : undefined
        },
        ...sxCard
      }}
    >
      <Radio
        sx={{ alignSelf: 'center' }}
        icon={
          isHovered ? <RadioButtonUnChecked color='var(--color-primary)' size={24} /> : undefined
        }
        value={value}
        checked={checked}
        size='medium'
      />

      {icon?.(color, 48)}
      <Stack component='section' direction='column'>
        <Typography
          sx={{
            color: typographyColor
          }}
          variant={checked ? 'body2_semi_bold' : 'body2'}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography sx={{ color: 'var(--color-text-body-b2)' }} variant='label1_semi_bold'>
            {subtitle}
          </Typography>
        )}
      </Stack>
    </Card>
  )
}
