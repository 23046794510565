import { type ReactNode, useState } from 'react'

import { Box, Collapse, Grid, Stack, Typography } from '@mui/material'
import { ChevronDownIcon, DocumentOutlinedIcon } from '@core/components/icons'
import { useLayout } from '@core/contexts/layout'
import { colors } from '@core/helpers'
import { type ProgramsAffiliate } from '@services/programs/types'

import { Afiliados } from '..'

import s from './Cards.module.scss'

interface Props {
  contract: ProgramsAffiliate
  icon?: ReactNode
}

export const CardCollapse = ({ contract, icon = <DocumentOutlinedIcon size={20} /> }: Props) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  const { isMobile } = useLayout()

  return (
    <Grid
      item
      sx={{ backgroundColor: 'white', borderRadius: '1.6rem' }}
      min={12}
      phone_lg={6}
      p='1.6rem'
      className={s['card-collapse']}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        borderRadius='1.6rem'
        flex={1}
      >
        <Box display='flex' gap='1.2rem' alignItems='flex-start' flex={1}>
          <Box p='1rem' sx={{ backgroundColor: colors.primary_lightest, borderRadius: '4rem' }}>
            {icon}
          </Box>
          <Stack flex={1}>
            <Typography variant='body2_bold' color={colors.text_title_p2}>
              Contrato #{contract.contractId}
            </Typography>
            <Typography variant='label1' color={colors.text_body_b2}>
              {contract.listadoAfi.length}{' '}
              {contract.listadoAfi.length === 1 ? 'miembro' : 'miembros'}
            </Typography>
            {!isMobile && (
              <Collapse in={isCollapseOpen} sx={{ display: 'flex', mr: '-1rem' }}>
                <Stack gap='3.2rem' mt='3.2rem'>
                  {contract.listadoAfi.map(afiliate => (
                    <Afiliados key={afiliate.affiliateId} affiliate={afiliate} />
                  ))}
                </Stack>
              </Collapse>
            )}
          </Stack>
        </Box>
        <Box
          component='button'
          onClick={() => setIsCollapseOpen(!isCollapseOpen)}
          pl='2.4rem'
          sx={{ cursor: 'pointer' }}
        >
          <Box className={`${s.chevron} ${isCollapseOpen ? s.rotate : ''}`} mt='1rem'>
            <ChevronDownIcon
              size={24}
              className={`${s.chevron} ${isCollapseOpen ? s.rotate : ''}`}
            />
          </Box>
        </Box>
      </Box>
      {isMobile && (
        <Collapse in={isCollapseOpen} sx={{ display: 'flex' }}>
          <Stack gap='3.2rem' mt='2.4rem'>
            {contract.listadoAfi.map(affiliate => (
              <Afiliados key={affiliate.affiliateId} affiliate={affiliate} />
            ))}
          </Stack>
        </Collapse>
      )}
    </Grid>
  )
}
